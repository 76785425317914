<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_request_select_instruction') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary" />
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <ValidationProvider
            :name="$t('transfer_request_instruction')"
            rules="required"
            v-slot="{ errors }"
          >
            <BaseList
              flat
              color="primary"
              :headers="headers"
              :items="instructions"
              :loading="loading"
              :totalRows="totalRows"
              :breadcrumbs="false"
              :elevation="0"
              show-select
              single-select
              v-model="permanentInstruction"
              item-key="transferInstructionId"
              :height="'100%'"
              :buttons="{search: false}"
              @refresh="getData"
              @options="handleOptions"
              :error-messages="errors[0]"
              @item-selected="handlePermanentInstruction"
            >
              <template #header.alias>
                <span> {{$t('transfer_index_alias')}} </span>
              </template>
              <template #header.trType>
                <span> {{$t('transfer_index_type')}} </span>
              </template>
              <template #header.bnfBankName>
                <span> {{$t('transfer_index_bank')}} </span>
              </template>
              <template #header.status>
                <span> {{$t('transfer_index_status')}} </span>
              </template>
              <template #header.bankAccountNumber>
                <span> {{$t('transfer_index_account')}} </span>
              </template>
              <template #header.operation>
                <span> {{$t('transfer_index_action')}} </span>
              </template>
              <template #item.bnfBankName="{ item }">
                <span>{{ item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName}}</span>
              </template>
              <template #item.status="{ item }">
                <span>{{ item.isCertified ? 'Certificada' : 'Debe Certificar'}}</span>
              </template>
              <template #item.bankAccountNumber="{ item }">
                <span>{{ item.accountType == 'nfiAccount' ? handleBankAccount(item.nfiAccountNumber, item.isCertified) : handleBankAccount(item.bnfBankAccNumber, item.isCertified)}}</span>
              </template>
            </BaseList>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import ClientTransferInstructionsService from '@/services/clientTransferInstructions'
import BaseList from '@/components/commons/BaseList'
import { mapState } from 'vuex'

export default {
  components: {
    WizardForm,
    BaseList
  },
  props: {
    clientRequest: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      checkbox: false,
      instructions: [],
      loading: false,
      totalRows: 0,
      permanentInstruction: [],
      headers: [
        { text: 'Alias', value: 'alias' },
        { text: 'Tipo de Instrucción', value: 'trType' },
        { text: 'Institución Financiera', value: 'bnfBankName' },
        { text: 'Status', value: 'status' },
        {
          text: 'Número de Cuenta',
          value: 'bankAccountNumber',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapState('account', ['account'])
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        let data = await ClientTransferInstructionsService.find(
          this.account.code
        )
        this.instructions = data.filter(item => item.isCertified)
        this.totalRows = this.instructions.length
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    handleOptions(options) {
      this.options = options
      this.getData()
    },
    handleBankAccount(acc, isCertified) {
      let account = isCertified ? acc : `...${acc.substring(acc.length - 4)}`
      return account
    },
    handlePermanentInstruction(event) {
      if (event.value) {
        this.$emit('selectInstruction', event.item)
      } else {
        this.$emit('clearInstruction')
      }
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>