<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_request_observations') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary" />
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_observations')"
            dense
            autocomplete="nope"
            v-model="clientRequest.permanentInstruction.bnfReference"
            maxlength="128"
            :disabled="clientRequest.permanentInstruction.accountType == 'nfiAccount'"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_instructions')"
            dense
            autocomplete="nope"
            v-model="clientRequest.permanentInstruction.avsInternalRemark"
            maxlength="128"
            :disabled="clientRequest.permanentInstruction.accountType == 'nfiAccount'"
          />
        </v-col>
      </v-row>
      <v-row v-if="isExecutive">
        <v-col cols="12">
          <div class="text-h6 font-weight-bold">
            {{ $t('transfer_request_authorization_file_title') }} *
          </div>
          <p>{{ $t('transfer_request_authorization_file_description') }}</p>
          <ul>
            <li>{{ $t('transfer_request_authorization_file_item_1') }}</li>
            <li>{{ $t('transfer_request_authorization_file_item_2') }}</li>
            <li>{{ $t('transfer_request_authorization_file_item_3') }}</li>
            <li>{{ $t('transfer_request_authorization_file_item_4') }}</li>
            <li>{{ $t('transfer_request_authorization_file_item_5') }}</li>
          </ul>
          <ValidationProvider
            :name="$t('transfer_request_authorization_document')"
            :rules="{
              required: true,
              ext: 'pdf',
              size: 300
            }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="file"
              :rules="rules"
              accept=".pdf"
              dense
              show-size
              outlined
              class="pt-2"
              :label="$t('transfer_request_authorization_document_upload')"
              @change="handleFile($event)"
              @click:clear="clearInputFile()"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import { mapState } from 'vuex'
export default {
  components: {
    WizardForm
  },
  props: {
    clientRequest: {
      type: [Object]
    }
  },
  data() {
    return {
      file: null,
      checkbox: false,
      stepTwo: {
        account_type: 'Cuentas de Bancos en Panamá'
      },
      items: [
        'Cuenta Corriente en Banco',
        'Cuenta Ahorro en Banco',
        'Cuenta Inversión en Institución Financiera no Banco'
      ],
      banks: ['Banco Mercantil', 'Banesco'],
      rules: [
        value =>
          !value ||
          value.size < 300000 ||
          `El archivo debe pesar menos de 300KB`
      ]
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    isExecutive() {
      const index = this.user?.avobjects?.findIndex(
        item => item.typeCode === 'authorityCode' && item.code == '1' // Code = 1 (Executive)
      )
      return index > -1
    }
  },
  methods: {
    handleFile(file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.clientRequest.authorizationFile = reader.result
      }
      reader.readAsDataURL(file)
    },
    clearInputFile() {
      this.file = null
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>
