var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('wizard-form',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h1',{staticClass:"primary--text  font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('transfer_request_select_instruction'))+" ")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('hr',{staticClass:"rounded primary",attrs:{"color":"primary"}}),_c('hr',{staticClass:"primary",attrs:{"size":"1","color":"primary"}})])])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_request_instruction'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseList',{attrs:{"flat":"","color":"primary","headers":_vm.headers,"items":_vm.instructions,"loading":_vm.loading,"totalRows":_vm.totalRows,"breadcrumbs":false,"elevation":0,"show-select":"","single-select":"","item-key":"transferInstructionId","height":'100%',"buttons":{search: false},"error-messages":errors[0]},on:{"refresh":_vm.getData,"options":_vm.handleOptions,"item-selected":_vm.handlePermanentInstruction},scopedSlots:_vm._u([{key:"header.alias",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_alias'))+" ")])]},proxy:true},{key:"header.trType",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_type'))+" ")])]},proxy:true},{key:"header.bnfBankName",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_bank'))+" ")])]},proxy:true},{key:"header.status",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_status'))+" ")])]},proxy:true},{key:"header.bankAccountNumber",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_account'))+" ")])]},proxy:true},{key:"header.operation",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_action'))+" ")])]},proxy:true},{key:"item.bnfBankName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isCertified ? 'Certificada' : 'Debe Certificar'))])]}},{key:"item.bankAccountNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? _vm.handleBankAccount(item.nfiAccountNumber, item.isCertified) : _vm.handleBankAccount(item.bnfBankAccNumber, item.isCertified)))])]}}],null,true),model:{value:(_vm.permanentInstruction),callback:function ($$v) {_vm.permanentInstruction=$$v},expression:"permanentInstruction"}})]}}])})],1)],1)]},proxy:true}])},'wizard-form',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }